import { useQuery } from 'react-query';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { getTemplateReviewCount } from '../../api/checker-maker';
import { CheckerMakerReviewStatusEnum } from '../../shared/checker-maker.interface';

export const useGetTemplateReviewCount = (status: CheckerMakerReviewStatusEnum) => {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getTemplateReviewCount, status],
    queryFn: () => getTemplateReviewCount(status),
    initialData: 0,
    placeholderData: 0,
    refetchInterval: 30_000, // 30s
    refetchOnWindowFocus: true
  });
};
