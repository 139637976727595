import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

interface RedirectTrafficInterface {
  from: string;
  to: string;
  preserveParams?: boolean;
}

function RedirectTraffic({ from, to, preserveParams }: Readonly<RedirectTrafficInterface>) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const originalRoute = location.pathname;

    if (!preserveParams) navigate(to);

    const query = location.search;
    const newRoute = originalRoute.replace(from, to);

    navigate(newRoute + query);
  }, [navigate, from, to, preserveParams, location]);

  return null;
}

export default RedirectTraffic;
