import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { sidebarMenuProps, getRoutePathFromKey } from '../../static/routes';
import './index.css';
import { filterRoutes } from '../../utils/route';
import { useAppConfig, useAuth } from '../../hooks/useAuth';
import { Sidebar, SidebarItemProps } from '@squantumengine/horizon';
import { useGetTemplateReviewCount } from './../../hooks/checker-maker-query/useGetTemplateReviewCount';
import { CheckerMakerReviewStatusEnum } from '../../shared/checker-maker.interface';

function SidebarComponent() {
  const { userAccess } = useAuth();
  const { isCheckerMaker, isRoleChecker } = useAppConfig();
  const navigate = useNavigate();
  const waitingForReviewCount = useGetTemplateReviewCount(
    isRoleChecker ? CheckerMakerReviewStatusEnum.REVIEW : CheckerMakerReviewStatusEnum.ADJUSTMENT
  );

  const routeConfig = useMemo(
    () => ({
      isCheckerMaker: isCheckerMaker,
      waitingForReviewCount: waitingForReviewCount.data as number
    }),
    [isCheckerMaker, waitingForReviewCount]
  );

  const menuNavigate = (key: SidebarItemProps) => {
    const path = getRoutePathFromKey(key.id, routeConfig);
    path && navigate(path);
  };

  const menuItems = useMemo(() => {
    const routes = sidebarMenuProps(routeConfig);
    if (!routes) return [];
    return filterRoutes(routes, userAccess);
  }, [userAccess, routeConfig]);

  return (
    <div data-testid="layout-sidebar" className="layout-sidebar left-0 bg-white pt-4">
      <Sidebar items={menuItems} onClick={menuNavigate} width={268} collapsible={true} />
    </div>
  );
}

export default SidebarComponent;
