import { lazy } from 'react';

const NotFound = lazy(() => import('./404'));
const AutomatedCampaignDetail = lazy(() => import('./campaign-detail'));
const Email = lazy(() => import('./template/email'));
const ChannelDetail = lazy(() => import('./channel-detail'));
const IndexPage = lazy(() => import('./Index/index'));
const SSOCallbackPage = lazy(() => import('./sso-callback/index'));
const AutomatedCampaignAnalytics = lazy(() => import('./automated-campaign-analytics/index'));
const Whatsapp = lazy(() => import('./template/whatsapp'));
const WhatsAppConfig = lazy(() => import('./whatsapp-config/index'));
const TestChannel = lazy(() => import('./test-channel'));
const PushNotification = lazy(() => import('./template/push-notification'));
const PushNotificationConfig = lazy(() => import('./push-notification-config'));
const EmailConfig = lazy(() => import('./email-config'));
const MPConfig = lazy(() => import('./mp-config'));
const TaggingConfig = lazy(() => import('./tagging-config'));
const Privacy = lazy(() => import('./legal/no-auth/Privacy'));
const Contact = lazy(() => import('./contact'));
const Login = lazy(() => import('./login'));
const CampaignPage = lazy(() => import('./campaign'));
const CampaignDetailPage = lazy(() => import('./campaign-details/index'));
const TermAndConditions = lazy(() => import('./legal/no-auth/TermAndConditions'));
const Users = lazy(() => import('./users'));
const Broadcast = lazy(() => import('./broadcast'));
const AuditPage = lazy(() => import('./audit'));
const UnsubscribeEmail = lazy(() => import('./unsubscribe-email/index'));
const Page403 = lazy(() => import('./403'));
const DomainDetail = lazy(() => import('./email-config/domain-detail'));
const UnsubscribePage = lazy(() => import('./unsubscribe-page'));
const CampaignApprovalPage = lazy(() => import('./campaign-approval'));
const RedirectChannel = lazy(() => import('./redirect-channel'));
const WaitForApprovalPage = lazy(() => import('./wait-for-approval'));

export {
  NotFound,
  AutomatedCampaignDetail,
  Email,
  ChannelDetail,
  IndexPage,
  SSOCallbackPage,
  AutomatedCampaignAnalytics,
  Whatsapp,
  WhatsAppConfig,
  TestChannel,
  PushNotification,
  PushNotificationConfig,
  EmailConfig,
  MPConfig,
  Privacy,
  Contact,
  Login,
  CampaignPage,
  TermAndConditions,
  Users,
  Broadcast,
  AuditPage,
  UnsubscribeEmail,
  Page403,
  DomainDetail,
  UnsubscribePage,
  CampaignApprovalPage,
  CampaignDetailPage,
  RedirectChannel,
  TaggingConfig,
  WaitForApprovalPage
};
