import { useMutation, useQuery } from 'react-query';
import { QUERY_KEYS_ENUM } from '../static/query-keys.enum';
import { inviteNewUser, logoutRequest } from '../api/auth';
import { LogoutPayloadInterface } from '../shared/user.interface';
import { handleAxiosError } from '../api/error-handler/error-handler';
import { useToastMessage } from './useQueryHelper';
import { SQEID_REDIRECT_URI } from '../utils/oAuth';

export const useLogout = () => {
  return useMutation({
    mutationKey: [QUERY_KEYS_ENUM.logout],
    mutationFn: (payload: LogoutPayloadInterface) => {
      return logoutRequest(payload);
    }
  });
};

export const useInviteNewUser = () => {
  const { toastMessage } = useToastMessage();
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.inviteNewUser],
    queryFn: inviteNewUser,
    enabled: false,
    onError: (error: unknown) => {
      const message = handleAxiosError(error);
      toastMessage('error', message);
    },
    onSuccess: ({ data }) => {
      window.location.href = data.invite_link + SQEID_REDIRECT_URI;
    }
  });
};
