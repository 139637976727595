import CryptoJS from 'crypto-js';

interface RandomStringGenerator {
  length?: number;
  useSpecialChar?: boolean;
}

export const generateRandomSecret = (
  params: RandomStringGenerator = {
    length: 64,
    useSpecialChar: true
  }
) => {
  let charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  charset = params.useSpecialChar ? `${charset}-._~` : charset;
  let codeVerifier = '';

  if (!params.length) return '';

  for (let i = 0; i < params.length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    codeVerifier += charset[randomIndex];
  }

  return codeVerifier;
};

export const encryptToken = (token?: string, secret?: string) => {
  if (!token) return '';
  if (!secret) return token;
  // @ts-ignore
  return CryptoJS.AES.encrypt(token, secret).toString();
};

export const decryptToken = (token?: string, secret?: string) => {
  if (!token) return '';
  if (!secret) return token;
  // @ts-ignore
  return CryptoJS.AES.decrypt(token, secret).toString(CryptoJS.enc.Utf8);
};

export const SQEID_REDIRECT_URI = `&redirect_uri=${window.location.origin}/pengguna`;
