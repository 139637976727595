import { useQuery } from 'react-query';
import { getSettings } from '../../api/settings';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { GetSettingsInterface } from '../../shared/settings.interface';

export const useGetSettings = ({
  refetchInterval,
  onSuccess
}: {
  refetchInterval?: number;
  onSuccess: (data: GetSettingsInterface) => void;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getSettings],
    queryFn: getSettings,
    refetchOnWindowFocus: true,
    refetchInterval,
    onSuccess
  });
};
