import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { logicalOperatorWrapper } from '../utils/logicalOperator';
import { Pagination } from './campaign.interface';

const { OR } = logicalOperatorWrapper;

export interface userProps {
  email: string;
  name: string;
  // somehow first_name and last_name no longer exist, but still listed for compatibility
  first_name: string;
  id: string;
  last_name: string;
  organization_id: string;
  profile_photo: string;
  role: UserRoleEnum;
  scopes: AuthActionScopeEnum[];
}

export interface tokenProps {
  access_token: string;
  refresh_token: string;
}

export interface UserAccessInterface {
  role: UserRoleEnum;
  scopes: Set<AuthActionScopeEnum>;
}

export interface AuthContextProps {
  userData: userProps;
  userAccess: UserAccessInterface;
  appConfig: {
    isCheckerMaker: boolean;
    setIsCheckerMaker: Dispatch<SetStateAction<boolean>>;
    isSettingsInitialized: MutableRefObject<boolean>;
  };
  login: (data: any) => void;
  logout: () => void;
}

export interface loginRequestProps {
  email: string;
  password: string;
}

export interface loginResponseProps {
  message: string;
  data: userProps;
  token: tokenProps;
}

export interface SSOLoginPayload {
  code_verifier: string;
  code_challenge: string;
  redirect_url: string;
}

export interface LogoutPayloadInterface {
  refresh_token: string;
}

export enum UserRoleEnum {
  ADMIN = 'admin',
  MANAGER = 'manager',
  EDITOR = 'editor'
}

export interface FetchUserListPayloadInterface {
  page: number;
  limit?: number;
  query: string;
  role: UserRoleEnum | '';
}

export interface UserInterface {
  id: string;
  name: string;
  email: string;
  phone: string;
  role: UserRoleEnum;
  created_at: number;
}

export interface FetchUserListResponseInterface {
  message: string;
  pagination: Pagination;
  data: UserInterface[];
}

export interface FetchEditUserLinkResponseInterface {
  edit_user_profile_link: string;
}

export interface FetchEditPermissionLinkResponseInterface {
  edit_permission_link: string;
}

// role enum shortening for convenience
export const ADMIN = UserRoleEnum.ADMIN;
export const MANAGER = UserRoleEnum.MANAGER;
export const EDITOR = UserRoleEnum.EDITOR;

export enum AuthActionScopeEnum {
  PROFILE_LOGIN = 'profile:login',
  PROFILE_LOGOUT = 'profile:logout',
  PROFILE_CHANGE_PASSWORD = 'profile:change-password',

  CAMPAIGN_CREATE = 'campaign:create',
  CAMPAIGN_READ = 'campaign:read',
  CAMPAIGN_WRITE = 'campaign:write',

  CAMPAIGN_LABEL_WRITE = 'campaign_label:write',
  CAMPAIGN_LABEL_READ = 'campaign_label:read',
  CAMPAIGN_LABEL_CREATE = 'campaign_label:create',
  CAMPAIGN_LABEL_DELETE = 'campaign_label:delete',

  AUDIT_READ = 'audit:read',
  AUDIT_EXPORT = 'audit:export',

  PROFILE_WRITE = 'user:update',
  USER_READ = 'user:read',
  USER_CREATE = 'user:invite-user',
  USER_WRITE = 'user:manage-role',
  USER_DELETE = 'user:delete',
  PERMISSION_WRITE = 'user:manage-permission',

  CONTACT_READ = 'contact:read',
  CONTACT_CREATE = 'contact:create',
  CONTACT_WRITE = 'contact:write',
  CONTACT_DELETE = 'contact:delete',
  CONTACT_EXPORT = 'contact:export',

  CHANNEL_EMAIL_CREATE = 'channel_email:create',
  CHANNEL_WA_CREATE = 'channel_whatsapp:create',
  CHANNEL_PN_CREATE = 'channel_push_notification:create',

  CHANNEL_EMAIL_WRITE = 'channel_email:write',
  CHANNEL_WA_WRITE = 'channel_whatsapp:write',
  CHANNEL_PN_WRITE = 'channel_push_notification:write',

  CHANNEL_EMAIL_READ = 'channel_email:read',
  CHANNEL_WA_READ = 'channel_whatsapp:read',
  CHANNEL_PN_READ = 'channel_push_notification:read',

  CHANNEL_EMAIL_TEST = 'channel_email:test',
  CHANNEL_WA_TEST = 'channel_whatsapp:test',
  CHANNEL_PN_TEST = 'channel_push_notification:test',

  ANALYTIC_EMAIL_READ = 'analytic_email:read',
  ANALYTIC_WA_READ = 'analytic_whatsapp:read',
  ANALYTIC_PN_READ = 'analytic_push_notification:read',

  ANALYTIC_EMAIL_EXPORT = 'analytic_email:export',
  ANALYTIC_WA_EXPORT = 'analytic_whatsapp:export',
  ANALYTIC_PN_EXPORT = 'analytic_push_notification:export',

  CHANNEL_CONFIG_MP_READ = 'secret:read',
  CHANNEL_CONFIG_EMAIL_READ = 'config_email:read',
  CHANNEL_CONFIG_WA_READ = 'config_whatsapp:read',
  CHANNEL_CONFIG_PN_READ = 'config_push_notification:read',

  CHANNEL_CONFIG_EMAIL_CREATE = 'config_email:create',
  CHANNEL_CONFIG_WA_CREATE = 'config_whatsapp:create',
  CHANNEL_CONFIG_PN_CREATE = 'config_push_notification:create',

  CHANNEL_CONFIG_PN_EXPORT = 'config_push_notification:export',

  CHANNEL_CONFIG_EMAIL_DELETE = 'config_email:delete',
  CHANNEL_CONFIG_WA_DELETE = 'config_whatsapp:delete',
  CHANNEL_CONFIG_PN_DELETE = 'config_push_notification:delete',

  SENDER_EMAIL_CREATE = 'sender_email:create',

  BROADCAST_EMAIL_CREATE = 'broadcast_email:create',
  BROADCAST_WA_CREATE = 'broadcast_whatsapp:create',
  BROADCAST_PN_CREATE = 'broadcast_push_notification:create',

  BROADCAST_EMAIL_READ = 'broadcast_email:read',
  BROADCAST_WA_READ = 'broadcast_whatsapp:read',
  BROADCAST_PN_READ = 'broadcast_push_notification:read',

  BROADCAST_WA_WRITE = 'broadcast_whatsapp:write',

  BROADCAST_EMAIL_DELETE = 'broadcast_email:delete',
  BROADCAST_WA_DELETE = 'broadcast_whatsapp:delete',
  BROADCAST_PN_DELETE = 'broadcast_push_notification:delete',

  UNSUBSCRIBE_READ = 'unsubscribe:read',
  UNSUBSCRIBE_WRITE = 'unsubscribe:write',
  UNSUBSCRIBE_DELETE = 'unsubscribe:delete',
  UNSUBSCRIBE_IMPORT = 'unsubscribe:import',
  UNSUBSCRIBE_EXPORT = 'unsubscribe:export',

  CONFIG_UNSUBSCRIBE_WRITE = 'config_unsubscribe:write',
  CONFIG_UNSUBSCRIBE_READ = 'config_unsubscribe:read',

  CATEGORY_EMAIL_READ = 'category_email:read',
  CATEGORY_EMAIL_CREATE = 'category_email:create',
  CATEGORY_EMAIL_DELETE = 'category_email:delete',

  DECRYPT_DATA = 'key:decrypt',

  ORGANIZATION_SETTINGS = 'org_settings:write',

  REVIEW_EMAIL_READ = 'email_review:read',
  REVIEW_WA_READ = 'whatsapp_review:read',
  REVIEW_PN_READ = 'push_notification_review:read',

  REVIEW_EMAIL_APPROVE = 'email_review:approve',
  REVIEW_WA_APPROVE = 'whatsapp_review:approve',
  REVIEW_PN_APPROVE = 'push_notification_review:approve',

  COMMENT_CREATE = 'comment:create',
  COMMENT_DELETE = 'comment:delete',
  COMMENT_RESOLVE = 'comment:resolve',
  COMMENT_WRITE = 'comment:write'
}

export const ScopeEnumKeySet = new Set(Object.keys(AuthActionScopeEnum));

export const SCOPES_LIST = Object.values(AuthActionScopeEnum) as AuthActionScopeEnum[];

export interface InviteUserInterface {
  message: string;
  data: {
    invite_link: string;
  };
}

export const ALL_CHANNEL_SCOPES = {
  CHANNEL_READ: OR(
    AuthActionScopeEnum.CHANNEL_EMAIL_READ,
    AuthActionScopeEnum.CHANNEL_WA_READ,
    AuthActionScopeEnum.CHANNEL_PN_READ
  ),
  CHANNEL_CREATE: OR(
    AuthActionScopeEnum.CHANNEL_EMAIL_CREATE,
    AuthActionScopeEnum.CHANNEL_WA_CREATE,
    AuthActionScopeEnum.CHANNEL_PN_CREATE
  ),
  CHANNEL_TEST: OR(
    AuthActionScopeEnum.CHANNEL_EMAIL_TEST,
    AuthActionScopeEnum.CHANNEL_WA_TEST,
    AuthActionScopeEnum.CHANNEL_PN_TEST
  ),
  CONFIG_READ: OR(
    AuthActionScopeEnum.CHANNEL_CONFIG_MP_READ,
    AuthActionScopeEnum.CHANNEL_CONFIG_EMAIL_READ,
    AuthActionScopeEnum.CHANNEL_CONFIG_WA_READ,
    AuthActionScopeEnum.CHANNEL_CONFIG_PN_READ,
    AuthActionScopeEnum.CAMPAIGN_LABEL_READ,
    AuthActionScopeEnum.ORGANIZATION_SETTINGS
  ),
  ANALYIC_READ: OR(
    AuthActionScopeEnum.ANALYTIC_EMAIL_READ,
    AuthActionScopeEnum.ANALYTIC_WA_READ,
    AuthActionScopeEnum.ANALYTIC_PN_READ
  ),
  BROADCAST_READ: OR(
    AuthActionScopeEnum.BROADCAST_EMAIL_READ,
    AuthActionScopeEnum.BROADCAST_WA_READ,
    AuthActionScopeEnum.BROADCAST_PN_READ
  )
};
