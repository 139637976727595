import { useQuery } from 'react-query';
import { getUserAuthData } from '../../api/auth';
import { loginResponseProps } from '../../shared/user.interface';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';

export const useGetUserAuthData = ({
  onSuccess
}: {
  onSuccess: (data: loginResponseProps) => void;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getUserAuth],
    queryFn: getUserAuthData,
    onSuccess,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true
  });
};
