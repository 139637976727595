import { memo } from 'react';
import { Button, Divider } from 'antd';
import ScopeWrapper from '../role-wrapper/scope-wrapper';
import { AuthActionScopeEnum } from '../../shared/user.interface';

interface UserDropdownOptionsProps {
  onClickPrivacyPolicy: () => void;
  onClickTermAndCondition: () => void;
  handleLogout: () => void;
  userData: {
    profile_photo: string;
    name: string;
    role: string;
    email: string;
  };
}
function UserDropdownOptions({
  onClickPrivacyPolicy,
  onClickTermAndCondition,
  handleLogout,
  userData
}: Readonly<UserDropdownOptionsProps>) {
  return (
    <div className="flex flex-col items-start gap-2 rounded-[8px] bg-white-100 p-3 text-base font-normal text-black-90 shadow-options">
      <div className="flex min-w-[287px] flex-col items-center justify-center gap-2 self-stretch">
        <img
          className="rounded-full border-2 border-solid border-border-gray object-cover"
          width={82}
          height={82}
          src={userData.profile_photo}
          alt="profile_pic"
        />
        <p className="m-0 text-base font-semibold">{userData.name}</p>
        <p className="m-0 text-base capitalize">{userData.role}</p>
        <p className="m-0 text-sm text-grey-100">{userData.email}</p>
        {/* <p className="m-0 text-sm text-grey-100">{userData.email}</p> */}
      </div>
      <Divider className="m-0 bg-grey-50" />
      <div className="flex flex-col gap-4 self-stretch py-2">
        {/* <Button
          type="text"
          className="h-fit cursor-pointer justify-start p-0 text-left hover:bg-transparent">
          Update Profil
        </Button> */}
        <Button
          type="text"
          className="h-fit cursor-pointer justify-start p-0 text-left hover:bg-transparent"
          onClick={onClickPrivacyPolicy}
        >
          Kebijakan Privasi
        </Button>
        <Button
          type="text"
          className="h-fit cursor-pointer justify-start p-0 text-left hover:bg-transparent"
          onClick={onClickTermAndCondition}
        >
          Syarat & Ketentuan
        </Button>
        <ScopeWrapper scope={AuthActionScopeEnum.PROFILE_LOGOUT}>
          <Divider className="m-0 bg-grey-50" />
          <Button
            type="text"
            className="h-fit cursor-pointer justify-start p-0 text-left text-[#ed1c24] hover:bg-transparent"
            onClick={handleLogout}
          >
            <span>Log out</span>
          </Button>
        </ScopeWrapper>
      </div>
    </div>
  );
}

export default memo(UserDropdownOptions);
