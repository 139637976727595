import { PaginatedAPIResponseInterface } from './general.interface';
import { ChannelKeyEnum } from './master-api.interface';
import { Pagination } from './campaign.interface';
import { CommentStatusEnum, CommentVisibilityEnum } from '../static/cheker-maker.enum';
import { PrecodingInterface } from './precoding.interface';

export enum CheckerMakerReviewStatusEnum {
  REVIEW = 'cm_review',
  ADJUSTMENT = 'cm_adjustment',
  EXPIRED = 'cm_expired',
  ACTIVE = 'active',
  DELETED = 'deleted'
}

export enum CheckerMakerActivityActionEnum {
  APPROVE = 'approve',
  REQUEST = 'request',
  DECLINE = 'decline',
  EXPIRED = 'expired'
}

export interface CheckerMakerReviewInterface {
  id: string;
  status: CheckerMakerReviewStatusEnum;
  channel_type: ChannelKeyEnum;
  creator: string;
  expires_at: string;
  channel: {
    id: string;
    name: string;
    description: string;
    campaign_id: string;
  };
  broadcast: {
    contact: {
      id: string;
      name: string;
      code: string;
      total_user: string;
    }[];
    id: string;
    next_execution_at: string;
  };
  last_activity: {
    action: CheckerMakerActivityActionEnum;
    timestamp: string;
    user_id: string;
    user_name: string;
  };
}

export type GetTemplateReviewListResponseInterface =
  PaginatedAPIResponseInterface<CheckerMakerReviewInterface>;

export interface PostCheckerMakerInterface {
  id: string;
  text: string;
  anchor: string;
  highlight: string;
}

export interface PostCheckerMakerResponseInterface {
  message: string;
  data: {
    id: string;
  };
}

export interface ParamsCommentListInterface {
  id: string;
  page: number;
  limit: number;
  status?: CommentStatusEnum;
  visibility?: CommentVisibilityEnum;
}

export interface CommentInterface {
  anchor: string;
  created_at: string;
  created_by: string;
  creator: string;
  edited: boolean;
  highlight: string;
  id: string;
  status: string;
  text: string;
  updated_at: string;
  visibility: CommentVisibilityEnum;
}

export interface GetCommentListResponseInterface {
  message: string;
  data: CommentInterface[];
  pagination: Pagination;
  request_id: string;
}

export interface EditCommentResponseInterface {
  message: string;
  data: CommentInterface;
}

export interface BroadcastDataInterface {
  code: string;
  contact: {
    code: string;
    id: string;
    name: string;
    total_user: string;
  }[];
  created_at: string;
  creator: string;
  day_of_month: string[];
  day_of_week: string[];
  end_at: string;
  id: string;
  interval: 'daily' | 'weekly' | 'monthly' | '';
  is_end_of_month: boolean;
  next_execution_at: string;
  precoding_mapping: {
    [key: string]: string;
  };
  status: string;
}

export interface PushNotificationChannelInterface {
  body: string;
  button_cta: string;
  category: string;
  content_json: string;
  deeplink: string;
  header: string;
  image: {
    download_url: string;
    id: string;
    name: string;
  };
  is_action_required: boolean;
  is_silence: boolean;
  precodings: string[];
  precoding_example: PrecodingInterface[];
}

interface ChannelResponseInterface<T> {
  campaign_id: string;
  campaign_name: string;
  channel: {
    key: string;
  };
  created_at: string;
  creator: string;
  description: string;
  id: string;
  name: string;
  sender: {
    created_at: string;
    id: string;
    name: string;
  };
  status: string;
  template: T;
  updated_at: string;
}

export interface LastActivityInterface {
  action: CheckerMakerActivityActionEnum;
  timestamp: string;
  user_id: string;
  user_name: string;
}

interface GetTemplateDetailData<T> {
  broadcast: BroadcastDataInterface;
  channel: ChannelResponseInterface<T>;
  created_at: string;
  creator: string;
  expires_at: string;
  id: string;
  last_activity: LastActivityInterface;
  status: CheckerMakerReviewStatusEnum;
  updated_at: string;
}

export interface GetTemplateDetailResponse<T = {}> {
  message: string;
  data: GetTemplateDetailData<T>;
}

export interface ReviewActivityInterface {
  id: string;
  created_at: string;
  creator: string;
  action: CheckerMakerActivityActionEnum;
}

export type GetReviewActivityResponseInterface =
  PaginatedAPIResponseInterface<ReviewActivityInterface>;
