import { SidebarProps, Icon } from '@squantumengine/horizon';
import { RouteFilterInterface } from '../utils/route';
import { ALL_CHANNEL_SCOPES, AuthActionScopeEnum } from '../shared/user.interface';
import { logicalOperatorWrapper } from '../utils/logicalOperator';
import { ReactNode } from 'react';

const { OR } = logicalOperatorWrapper;

export interface SidebarRouteInterface extends RouteFilterInterface {
  id: string;
  icon?: ReactNode;
  text: ReactNode;
  title: ReactNode;
  url: string;
  children?: SidebarRouteInterface[];
  alert?: ReactNode;
}

interface SidebarRoutesProps {
  isCheckerMaker: boolean;
  waitingForReviewCount: number;
}

const NotificationCount = ({ count }: Readonly<{ count: number }>) => {
  if (!count) return null;

  return (
    <span className="flex h-4 min-w-4 items-center justify-center rounded-full bg-red-500 px-1 text-xs font-semibold text-white">
      <span className="translate-y-[0.5px]">{count}</span>
    </span>
  );
};

const sideBarRoutes: (props: SidebarRoutesProps) => SidebarRouteInterface[] = (props) => [
  {
    id: 'contact',
    icon: <Icon name="address-book" size="md" />,
    text: 'Kontak',
    title: 'Kontak',
    url: 'contact',
    scope: OR(AuthActionScopeEnum.CONTACT_READ, AuthActionScopeEnum.UNSUBSCRIBE_READ),
    children: [
      {
        id: 'list',
        text: 'Daftar Kontak',
        title: 'Daftar Kontak',
        url: 'list',
        scope: AuthActionScopeEnum.CONTACT_READ
      },
      {
        id: 'unsubscribe-email',
        text: 'Unsubscribe Email',
        title: 'Unsubscribe Email',
        url: 'unsubscribe-email',
        scope: AuthActionScopeEnum.UNSUBSCRIBE_READ
      }
    ]
  },
  ...(props.isCheckerMaker
    ? [
        {
          id: 'campaign',
          icon: <Icon name="bullhorn" size="md" />,
          text: 'Campaign',
          title: 'Campaign',
          url: 'campaign',
          scope: AuthActionScopeEnum.CAMPAIGN_READ,
          alert: <NotificationCount count={props.waitingForReviewCount} />,
          children: [
            {
              id: 'campaign-page',
              text: 'Semua Campaign',
              title: 'Semua Campaign',
              url: ''
            },
            {
              id: 'waiting-for-approval',
              text: 'Daftar Tugas',
              title: 'Daftar Tugas',
              url: 'menunggu-persetujuan',
              alert: <NotificationCount count={props.waitingForReviewCount} />
            }
          ]
        }
      ]
    : [
        {
          id: 'campaign',
          icon: <Icon name="bullhorn" size="md" />,
          text: 'Campaign',
          title: 'Campaign',
          url: 'campaign',
          scope: AuthActionScopeEnum.CAMPAIGN_READ,
          children: [
            {
              id: 'campaign-page',
              text: 'Semua Campaign',
              title: 'Semua Campaign',
              url: ''
            }
          ]
        }
      ]),
  {
    id: 'konfigurasi',
    icon: <Icon name="default" size="md" />,
    text: 'Konfigurasi',
    title: 'Konfigurasi',
    url: 'konfigurasi',
    scope: OR(
      ALL_CHANNEL_SCOPES.CONFIG_READ,
      AuthActionScopeEnum.CATEGORY_EMAIL_READ,
      AuthActionScopeEnum.CONFIG_UNSUBSCRIBE_READ
    ),
    children: [
      {
        id: 'setup-mp',
        text: 'Setup MP',
        title: 'Setup MP',
        url: 'setup-mp',
        scope: AuthActionScopeEnum.CHANNEL_CONFIG_MP_READ
      },
      {
        id: 'email',
        text: 'Email',
        title: 'Email',
        url: 'email',
        scope: OR(
          AuthActionScopeEnum.CHANNEL_CONFIG_EMAIL_READ,
          AuthActionScopeEnum.CATEGORY_EMAIL_READ,
          AuthActionScopeEnum.CONFIG_UNSUBSCRIBE_READ
        )
      },
      {
        id: 'whatsapp',
        text: 'WhatsApp',
        title: 'WhatsApp',
        url: 'whatsapp',
        scope: AuthActionScopeEnum.CHANNEL_CONFIG_WA_READ
      },
      {
        id: 'push-notification',
        text: 'Push Notification',
        title: 'Push Notification',
        url: 'push-notification',
        scope: AuthActionScopeEnum.CHANNEL_CONFIG_PN_READ
      },
      {
        id: 'tagging',
        text: 'Tagging',
        title: 'Tagging',
        url: 'tagging',
        scope: AuthActionScopeEnum.CAMPAIGN_LABEL_READ
      },
      {
        id: 'campaign-approval',
        text: 'Campaign approval',
        title: 'Campaign approval',
        url: 'campaign-approval',
        scope: AuthActionScopeEnum.ORGANIZATION_SETTINGS
      }
    ]
  },
  {
    id: 'pengguna',
    icon: <Icon name="users" size="md" />,
    text: 'Pengguna',
    title: 'Pengguna',
    url: 'pengguna',
    scope: AuthActionScopeEnum.USER_READ
  },
  {
    id: 'audit',
    icon: <Icon name="file-search" size="md" />,
    text: 'Audit',
    title: 'Audit',
    url: 'audit',
    scope: AuthActionScopeEnum.AUDIT_READ
  }
];
export interface MenuProps extends SidebarProps {}

function createMenuProps(routes?: SidebarRouteInterface[], parentKey: string = '') {
  if (!routes) return undefined;

  const menuProps: MenuProps['items'] = [];

  for (const route of routes) {
    const key = parentKey + route.url;
    const menuItem = {
      id: key,
      icon: route.icon,
      text: route.title,
      label: route.title,
      url: '/' + route.url,
      key,
      children: createMenuProps(route.children, `${route.url}-`),
      scope: route.scope,
      roles: route.roles,
      enable: route.enable,
      alert: route.alert
    };

    menuProps.push(menuItem);
  }

  return menuProps;
}

export const sidebarMenuProps: (props: SidebarRoutesProps) => MenuProps['items'] = (props) => {
  const routes = sideBarRoutes(props);
  return createMenuProps(routes)!;
};

export const getRoutePathFromKey = (key: string, props: SidebarRoutesProps) => {
  const stack: string[] = [];

  const findRoute = (routes: MenuProps['items']) => {
    for (const route of routes) {
      if (route.id === key) {
        if (route.children) return false;
        stack.push((route as any).url);
        return true;
      }

      if ((route as any).children) {
        stack.push((route as any)!.key);
        if (findRoute((route as any).children)) {
          return true;
        }
        stack.pop();
      }
    }

    return false;
  };

  if (!findRoute(sidebarMenuProps(props))) return undefined;

  const finalPath = stack.join('');
  const prependedPath = finalPath.startsWith('/') ? finalPath : '/' + finalPath;

  return prependedPath;
};
