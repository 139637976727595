import { useQuery } from 'react-query';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { getNotificationCount } from '../../api/notification';
import { NotificationCountResponse } from '../../shared/notification.interface';

const defaultResponse: NotificationCountResponse = { unseen: 0 };

export const useGetNotificationCount = () => {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getNotificationCount],
    queryFn: getNotificationCount,
    initialData: defaultResponse,
    placeholderData: defaultResponse
  });
};
