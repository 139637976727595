import { useParams } from 'react-router-dom';
import { Hook } from '@squantumengine/horizon';
import { generateRandomSecret } from '../utils/oAuth';
import { NoticeType } from 'antd/es/message/interface';

export interface ToastInterface {
  id?: string;
  label: string;
  buttonLabel?: string;
  onActionClick?: () => void;
  isError?: boolean;
}

export const useToastMessage = () => {
  const { useToasterProvider } = Hook;
  const { open } = useToasterProvider();

  const openToast = (props: ToastInterface) => {
    open({
      ...props,
      id: props.id ?? generateRandomSecret(),
      label: (
        <span className="sqeui-toast-label" data-testid="sqeui-toast">
          {props.label}
        </span>
      ),
      variant: props.isError ? 'secondary' : 'primary'
    });
  };

  // created for compatibility reasons to match the previous implementation
  // since it was used in a lot of files
  const toastMessage = (type: NoticeType, content: string) => {
    openToast({ label: content, isError: type === 'error' });
  };

  return { openToast, toastMessage };
};

function useQueryHelper() {
  const params = useParams();
  const { toastMessage } = useToastMessage();

  return { params, toastMessage };
}

export default useQueryHelper;
