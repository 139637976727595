import { useInfiniteQuery, useQueryClient } from 'react-query';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { getNotificationList } from '../../api/notification';
import { getInfiniteQueryNextParams } from '../../utils/getInfiniteQueryNextParams';

export const useGetNotificationList = () => {
  const queryClient = useQueryClient();
  return useInfiniteQuery({
    queryKey: [QUERY_KEYS_ENUM.getNotificationList],
    queryFn: ({ pageParam = 1 }) => getNotificationList(pageParam),
    getNextPageParam: getInfiniteQueryNextParams,
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_ENUM.getNotificationCount);
    },
    refetchInterval: 6_000 // 1 minute
  });
};
