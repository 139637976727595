import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const BullhornSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M26.6667 13.4117V7.07837C26.6667 6.70337 26.375 5.70337 25.3333 5.70337C25 5.70337 24.7083 5.82837 24.5 6.0367L20.9167 8.87004C19.1667 10.2867 16.9167 11.0367 14.6667 11.0367H6.66667C5.16667 11.0367 4 12.245 4 13.7034V17.7034C4 19.2034 5.16667 20.37 6.66667 20.37L6.625 21.7034C6.625 23.37 7 24.9534 7.70833 26.3284C7.91667 26.7867 8.375 27.0367 8.875 27.0367H13.2917C14.375 27.0367 15.0417 25.8284 14.375 24.9534C13.7083 24.0367 13.2917 22.9534 13.2917 21.7034C13.2917 21.245 13.375 20.8284 13.4583 20.37H14.6667C16.9167 20.37 19.1667 21.1617 20.9167 22.5784L24.5 25.4117C24.7083 25.62 25 25.7034 25.2917 25.7034C26.3333 25.7034 26.625 24.7867 26.625 24.37V18.0367C27.4583 17.5784 27.9583 16.7034 27.9583 15.745C28 14.745 27.4583 13.87 26.6667 13.4117ZM9.29167 25.0367C8.83333 23.995 8.625 22.87 8.625 21.7034C8.625 21.12 8.70833 20.6617 8.75 20.37H11.4583C11.3333 20.8284 11.2917 21.2867 11.2917 21.7034C11.2917 22.9117 11.5833 24.0367 12.0833 25.0367H9.29167ZM14 18.37H6.66667C6.29167 18.37 6 18.0784 6 17.7034V13.7034C6 13.37 6.29167 13.0367 6.66667 13.0367H14V18.37ZM24.6667 22.995L22.1667 21.0367C20.4167 19.62 18.25 18.745 16 18.495V12.9534C18.25 12.7034 20.4167 11.8284 22.1667 10.4117L24.6667 8.45337V22.995Z"
      fill="#1E252E"
    />
  </svg>
);

const BullhornIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={BullhornSVG} {...props} />
);

export default BullhornIcon;
