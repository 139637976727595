import useSimasTokens, { useLogoutUserApp } from '@squantumengine/sqeid-react-browser';
import { useLogout } from './auth-query';
import { useAuth } from './useAuth';
import { useNavigate } from 'react-router';

const useLogoutHook = (savePath: boolean = false) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { mutateAsync } = useLogout();
  const logoutSQEID = useLogoutUserApp();
  const simasToken = useSimasTokens();

  const handleLogout = async () => {
    await mutateAsync({ refresh_token: simasToken.refreshToken ?? '' });
    logout();
    logoutSQEID({ successCallbackFn: savePath ? undefined : () => navigate('/login') });
  };

  return handleLogout;
};

export default useLogoutHook;
