import { InfiniteData, QueryOptions } from 'react-query';
import { PaginatedAPIResponseInterface } from '../shared/general.interface';

export const getInfiniteQueryNextParams: QueryOptions<
  PaginatedAPIResponseInterface<any>,
  unknown,
  InfiniteData<PaginatedAPIResponseInterface<any>>,
  string
>['getNextPageParam'] = (lastPage, pages) => {
  const itemsTotal = lastPage.pagination.total_items;
  const fetchedTotal = pages.reduce((a, b) => a + b.pagination.item_count, 0);
  return fetchedTotal >= itemsTotal ? undefined : lastPage.pagination.page + 1;
};
