import { Outlet } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import { Suspense, useMemo } from 'react';
import { SimasProtectedRoute, useIsLoggedIn } from '@squantumengine/sqeid-react-browser';
import { useAuth } from '../hooks/useAuth';
import { Token } from '@squantumengine/horizon';
import { useGetUserAuthData } from '../hooks/auth/useGetAuthUserData';
import UpdateCheckerMaker from '../components/checker/update-checker-maker';

const { COLORS } = Token;

interface ProtectedLayoutProps {
  allowGuest?: boolean;
}

const AuthenticatedHandler = () => {
  const { login } = useAuth();
  useGetUserAuthData({ onSuccess: login });
  return null;
};

export const ProtectedLayoutContent = ({ allowGuest }: Readonly<ProtectedLayoutProps>) => {
  const isLoggedIn = useIsLoggedIn();
  const { userData } = useAuth();

  // here accessToken is intentionally used since
  // sometimes isLoggedIn takes a while to update after logging in
  if (!userData.id && !allowGuest) {
    window.location.href = '/login';
    return null;
  }

  return (
    <Layout className="h-screen">
      <Header />
      <Layout className="flex flex-row">
        {isLoggedIn && (
          <>
            <AuthenticatedHandler />
            <UpdateCheckerMaker />
            <Sidebar />
          </>
        )}
        <Layout
          className="sqemp-layout-content"
          style={{ overflow: 'auto', background: COLORS.neutral[50] }}
        >
          <Suspense
            fallback={
              <div className="flex h-full w-full items-center justify-center">
                <Spin size="large" />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </Layout>
      </Layout>
    </Layout>
  );
};

export const ProtectedLayout = ({ allowGuest }: Readonly<ProtectedLayoutProps>) => {
  const content = useMemo(() => {
    if (allowGuest) return <ProtectedLayoutContent allowGuest />;

    return (
      <SimasProtectedRoute
        loggedOutCallbackUri={
          '/login?to=' + encodeURIComponent(window.location.pathname + window.location.search)
        }
      >
        <ProtectedLayoutContent />
      </SimasProtectedRoute>
    );
  }, [allowGuest]);

  return content;
};
